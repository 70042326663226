<template>
  <div class="container">
    <div class="text-end p-3">
      <c-primary-button to="/admin/configuraciones/tipos-de-verificacion/crear">
        Crear tipo de verificación
      </c-primary-button>
    </div>
    <c-card title="Tipos de verificación">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Tipo de verificación</th>
            <th>Descripción</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="verificacion in verificaciones" :key="verificacion.id">
            <td>{{ verificacion.name }}</td>
            <td>{{ verificacion.description }}</td>
            <td class="text-nowrap">
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/configuraciones/tipos-de-verificacion/${verificacion.id}/editar`"
                title="Editar"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deleteVerificacion(verificacion.id)"
                title="Eliminar"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { all, remove } from "@/services/verificaciones";
import { showNotification } from "@/services/notification";

export default {
  components: {
    CCard,
    CPrimaryButton,
  },
  data() {
    return {
      verificaciones: [],
    };
  },
  mounted() {
    this.getVerificaciones();
  },
  methods: {
    getVerificaciones() {
      all()
        .then((response) => {
          this.verificaciones = response.data;
        })
        .catch(() => {
          alert("No pudimos cargar las categorias");
        });
    },
    deleteVerificacion(id) {
      const removeRecord = confirm("¿Estás seguro de eliminar el registro?");
      if (!removeRecord) {
        return;
      }
      remove(id).then(() => {
        showNotification(204, "success", "El registro fue eliminado.");
        this.getVerificaciones();
      });
    },
  },
};
</script>
